import React from 'react';
import Header from '../components/TheHeader';
import PrivacyPolicy from '../components/PrivacyPolicy';
import Footer from '../components/Footer';

/** Вьшка с политикой конфиденциальности */
function PrivacyPolicyView({ lang }) {
    return (
        <>
            <Header alwaysWhite={true} />
            <PrivacyPolicy lang={lang} />
            <Footer />
        </>
    );
}

export default PrivacyPolicyView;
