import React from 'react';
import * as styles from './GettingStarted.scss';
import ScreenshotIphone8 from './common/ScreenshotIphone8';
import AppStoreButton from './common/AppStoreButton';
import GooglePlayButton from './common/GooglePlayButton';
import { useTranslation } from 'react-i18next';

/**
 * Мини-полоска с описанием проекта и кнопками установки
 */
function GettingStarted() {
	const { t, i18n } = useTranslation();

	return (
		<section className="getting-started">
			<div className="container getting-started__body">
				<div className="getting-started__body-text">
					<div className="getting-started__body-text-title">{t('gettingStarted.title')}</div>
					<div className="getting-started__body-text-description">{t('gettingStarted.description')}</div>
					<div className="getting-started__body-text-actions">
						<AppStoreButton />
						<GooglePlayButton />
					</div>
				</div>
				<div className="getting-started__body-image">
					<ScreenshotIphone8 className="marvel-device-scale">
						<img src={`../../assets/screenshots/calendar${t('common.name')}.png`} />
					</ScreenshotIphone8>
				</div>
			</div>
		</section>
	);
}

export default GettingStarted;
