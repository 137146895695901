import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

i18n.use(Backend)
	.use(detector)
	.use(initReactI18next)
	.init({
		detection: {
			// Сначала смотрим на localStorage
			order: ['localStorage', 'navigator', 'cookie', 'querystring', 'htmlTag'],
			lookupLocalStorage: 'i18nextLng',
		},
		fallbackLng: 'ru',
		backend: {
			loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
		},
		debug: true,
		ns: ['translations'],
		defaultNS: 'translations',
		keySeparator: false,
		interpolation: {
			escapeValue: false,
			formatSeparator: ',',
		},
		react: {
			wait: true,
		},
	});

export default i18n;
