import React, { useState } from 'react';
import * as styles from './Feedback.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

/*
 * Отзывы и статистика
 */
function Feedback() {
	//стартовать ли счётчкикам статистики
	const [needStart, setNeedStart] = useState(false);

	// проверка на необходимость старта старт
	const onVisibilityChange = isVisible => {
		if (isVisible) {
			setNeedStart(true);
		}
	};

	// Статистика
	const statistic = [
		{
			title: 'Скачиваний',
			value: 13000,
		},
		{
			title: 'Рейтинг',
			value: 5,
		},
		{
			title: 'Вероятность успеха',
			value: 100,
		},
	];

	// Настройки для слайдера
	const sliderSettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: '0px',
		autoplay: true,
		autoplaySpeed: 2500,
		adaptiveHeight: true,
		arrows: false,
	};

	// Отзывы
	const reviews = [
		{
			photo: '../assets/avatar1.jfif',
			name: 'Travolta',
			review: 'Good job1 Good job1 Good Good Good Good job1 Good job1 Good job1 Good job1 Good job1 Good job1 ',
		},
		{
			photo: '../assets/avatar2.jfif',
			name: 'Adam',
			review:
				'Супер приложение, пользуюсь каждый день!!! Cмогли создать это!!! Никогда бы не поверил, что кто-нибудь способен создать ТАКОЕ!!! Так интересно ',
		},
		{
			photo: '../assets/avatar3.jfif',
			name: 'Donald Tr.',
			review: 'Very GOOD!!! Make AppStore Great Again!!!',
		},
		{
			photo: '../assets/avatar1.jfif',
			name: '2Travolta',
			review: 'Good job1 Good job1 Good Good Good Good job1 Good job1 Good job1 Good job1 Good job1 Good job1 ',
		},
		{
			photo: '../assets/avatar2.jfif',
			name: '2Adam',
			review:
				'Супер приложение, пользуюсь каждый день!!! Cмогли создать это!!! Никогда бы не поверил, что кто-нибудь способен создать ТАКОЕ!!! Так интересно ',
		},
		{
			photo: '../assets/avatar3.jfif',
			name: '2Donald Tr.',
			review: 'Very GOOD!!! Make AppStore Great Again!!!',
		},
	];

	return (
		<VisibilitySensor onChange={onVisibilityChange} delayedCall>
			<div className="feedback">
				<div className="feedback__statistic">
					<div className="feedback__statistic-title">Статистика приложения</div>
					<div className="feedback__statistic-list">
						{statistic.map((item, index) => {
							return (
								<div className="feedback__statistic-list-item">
									<div className="feedback__statistic-list-item-title">{item.title}</div>
									<div className="feedback__statistic-list-item-value">
										{index == 0 ? '>' : ''}
										<CountUp
											end={needStart ? item.value : 0}
											startOnMount={false}
											duration={5}
											separator=" "
										/>
										{index == 2 ? '%' : ''}
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="feedback__reviews">
					<div className="feedback__reviews-title">Отзывы довольных пользователей</div>
					<Slider {...sliderSettings}>
						{reviews.map(item => {
							return (
								<div className="feedback__reviews-carousel-item">
									<div className="feedback__reviews-carousel-item-header">
										<div className="feedback__reviews-carousel-item-header-photo">
											<img src={item.photo} />
										</div>
										<div className="feedback__reviews-carousel-item-header-name">{item.name}</div>
									</div>
									<div className="feedback__reviews-carousel-item-review">{item.review}</div>
								</div>
							);
						})}
					</Slider>
				</div>
			</div>
		</VisibilitySensor>
	);
}

export default Feedback;
