import React from 'react';
import * as styles from './Advantages.scss';
import { useTranslation } from 'react-i18next';

/**
 * Преимущества проекта
 */
function Advantages() {
	const { t, i18n } = useTranslation();

	const advantages = [
		{
			icon: 'fa-tasks',
			color: '#42c2ff',
			title: t('advantages.tasks.title'),
			description: t('advantages.tasks.description'),
		},
		{
			icon: 'fa-users',
			color: '#ff5240',
			title: t('advantages.clients.title'),
			description: t('advantages.clients.description'),
		},
		{
			icon: 'fa-bar-chart',
			color: '#8f62e6',
			title: t('advantages.projects.title'),
			description: t('advantages.projects.description'),
		},
		{
			icon: 'fa-compass',
			color: '#ffa92e',
			title: t('advantages.routing.title'),
			description: t('advantages.routing.description'),
		},
		{
			icon: 'fa-phone-square',
			color: '#8bdb65',
			title: t('advantages.calls.title'),
			description: t('advantages.calls.description'),
		},
		{
			icon: 'fa-file-text',
			color: '#476dd5',
			title: t('advantages.reports.title'),
			description: t('advantages.reports.description'),
		},
	];

	return (
		<section className="advantages" id="advantages">
			<div className="container">
				<h1>{t('advantages.title')}</h1>
				<div className="advantages__container">
					{advantages.map((advantage, index) => (
						<div key={index} className="advantages__container-item wow fadeInUp" data-wow-delay={`${index * 0.1}s`}>
							<i className={`fa ${advantage.icon} fa-2x`} style={{ color: advantage.color }} />
							<div className="advantages__container-item-text">
								<span className="advantages__container-item-text-title">{advantage.title}</span>
								<span className="advantages__container-item-text-description">{advantage.description}</span>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default Advantages;
