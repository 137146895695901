import React from 'react';
import * as styles from './DownloadButton.scss';

/**
 * Кнопка для скачивания
 */
function GooglePlayButton(props) {
	return (
		<a
			class={`download-button ${props.className}`}
			target="_blank"
			href="https://play.google.com/store/apps/details?id=com.a2soft.worknote&pageId=none"
		>
			<img src="assets/googleplay.png" alt="Google play" />
		</a>
	);
}

export default GooglePlayButton;
