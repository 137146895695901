import React from 'react';
import * as styles from './Footer.scss';
import AppStoreButton from './common/AppStoreButton';
import GooglePlayButton from './common/GooglePlayButton';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';

/**
 * Футер
 */
function Footer() {
	const { t, i18n } = useTranslation();

	return (
		<div className="footer">
			<div className="container">
				<div className="footer__body">
					<div className="footer__body-info">
						<a href="/#">
							<img src="/brand.png" className="footer__body-info-logo" />
							<span>{t('footer.label')}</span>
						</a>
						<span class="footer__body-info-description">{t('footer.description')}</span>
					</div>
					<div className="footer__body-links">
						<div className="footer__body-links-contents">
							<div>
								<Link to='/#faq'>{t('footer.faq')}</Link>
							</div>
							<div>
								<Link to='/#advantages'>{t('footer.advantages')}</Link>
							</div>
							<div>
								<Link to='/#contacts'>{t('footer.contacts')}</Link>
							</div>
							<div>
								<Link to='/privacy-policy'>{t('footer.privacyPolicy')}</Link>
							</div>
						</div>
						{/* <div className="footer__body-links-social">
							<div>
								<a href="#">Twitter</a>
							</div>
							<div>
								<a href="#">Facebook</a>
							</div>
							<div>
								<a href="#">Instagram</a>
							</div>
							<div>
								<a href="#">Dribbble</a>
							</div>
						</div> */}
						<div className="footer__body-links-actions">
							<AppStoreButton className="footer__body-links-actions-item" />
							<GooglePlayButton className="footer__body-links-actions-item" />
						</div>
					</div>
				</div>
				<div className="footer__copyright">
					<span className="footer__copyright-text">© 2020 A2Soft</span>
				</div>
			</div>
		</div>
	);
}

export default Footer;
