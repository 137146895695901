import React, { useEffect, Suspense } from 'react';
import './style/devices.scss';
import './style/animate.css';
import './App.scss';
import MainView from './views/MainView';
import PrivacyPolicyView from './views/PrivacyPolicyView';
import AnimationService from './services/AnimationService';
import { YMInitializer } from 'react-yandex-metrika';
import './services/MultilanguageService';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";

function App() {
	useEffect(() => {
		AnimationService.init();
	}, []);

	return (
		<Router>
			<div className="App">
				<Suspense fallback={null}>
					<Switch>
						<Route exact path="/" component={MainView} />
						<Route path="/privacy-policy" component={PrivacyPolicyView} />
						<Route exact path="/privacy-policy.html" component={() => <PrivacyPolicyView lang="ru" />} />
						<Route exact path="/privacy-policy_en.html" component={() => <PrivacyPolicyView lang="en" />} />
					</Switch>

					<YMInitializer accounts={[59032207]} options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true }} />
				</Suspense>
			</div>
		</Router>
	);
}

export default App;
