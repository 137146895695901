import React from 'react';
import * as styles from './Cases.scss';
import CaseItem from './CaseItem';
import { useTranslation } from 'react-i18next';

/**
 * Кейсы проекта
 */
function Cases({}) {
	const { t, i18n } = useTranslation();

	const items = [
		{
			image: `../assets/screenshots/clients${t('common.name')}.png`,
			headerIcon: 'fa-line-chart',
			color: '#ff0000',
			header: t('cases.new.title'),
			description: t('cases.new.description'),
		},
		{
			image: `../assets/screenshots/calendar-actions${t('common.name')}.png`,
			headerIcon: 'fa-calendar',
			color: '#42c2ff',
			header: t('cases.tasks.title'),
			description: t('cases.tasks.description'),
		},
		{
			image: `../assets/screenshots/project-comments${t('common.name')}.png`,
			headerIcon: 'fa-bar-chart',
			color: '#8f62e6',
			header: 'Проекты',
			header: t('cases.projects.title'),
			description: t('cases.projects.description'),
		},
		{
			image: `../assets/screenshots/client-communications${t('common.name')}.png`,
			headerIcon: 'fa-phone-square',
			color: '#8bdb65',
			header: t('cases.calls.title'),
			description: t('cases.calls.description'),
		},
		{
			image: `../assets/screenshots/project${t('common.name')}.png`,
			headerIcon: 'fa-map-signs',
			color: '#ffa92e',
			header: t('cases.routing.title'),
			description: t('cases.routing.description'),
		},
		{
			image: `../assets/screenshots/main${t('common.name')}.png`,
			headerIcon: 'fa-file-text-o',
			color: '#476dd5',
			header: t('cases.reports.title'),
			description: t('cases.reports.description'),
		},
	];

	return (
		<section className="cases" id="cases">
			{items.map((item, index) => {
				return (
					<CaseItem
						key={index}
						image={item.image}
						headerIcon={item.headerIcon}
						color={item.color}
						header={item.header}
						description={item.description}
						isReverse={!(index % 2 == 0)}
					/>
				);
			})}
		</section>
	);
}

export default Cases;
