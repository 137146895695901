import React from 'react';

/**
 * Скрин на Iphone XS
 */
function ScreenshotIphoneXs(props) {
	return (
		<div class={`marvel-device iphone-x ${props.className}`}>
			<div class="notch">
				<div class="camera"></div>
				<div class="speaker"></div>
			</div>
			<div class="top-bar"></div>
			<div class="sleep"></div>
			<div class="bottom-bar"></div>
			<div class="volume"></div>
			<div class="overflow">
				<div class="shadow shadow--tr"></div>
				<div class="shadow shadow--tl"></div>
				<div class="shadow shadow--br"></div>
				<div class="shadow shadow--bl"></div>
			</div>
			<div class="inner-shadow"></div>
			<div class="screen">{props.children}</div>
		</div>
	);
}

export default ScreenshotIphoneXs;
