import React from 'react';
import * as styles from './DownloadButton.scss';

/**
 * Кнопка для скачивания
 */
function AppStoreButton(props) {
	return (
		<a
			class={`download-button ${props.className}`}
			target="_blank"
			href="https://apps.apple.com/ru/app/worknote/id1500394411"
		>
			<img src="assets/appstore.png" alt="Apple App Store" />
		</a>
	);
}

export default AppStoreButton;
