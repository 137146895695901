import React from 'react';
import * as styles from './Hero.scss';
import ScreenshotIphoneXs from './common/ScreenshotIphoneXs';
import AppStoreButton from './common/AppStoreButton';
import GooglePlayButton from './common/GooglePlayButton';
import { useTranslation } from 'react-i18next';

function Hero() {
	const { t, i18n } = useTranslation();

	return (
		<section className="hero">
			<div className="container hero__body">
				<div className="hero__body-image wow fadeInLeft" data-wow-delay="0.3s">
					<ScreenshotIphoneXs className="marvel-device-scale">
						<img src={`/assets/screenshots/main${t('common.name')}.png`} />
					</ScreenshotIphoneXs>
				</div>
				<div className={`hero__body-title wow fadeInUp`}>
					<h1 className="hero__body-title-name">WorkNote</h1>
					<div className="hero__body-title-description">{t('hero.description')}</div>
					<div className="hero__body-title-description">{t('hero.footer')}</div>
					<div className="hero__body-title-actions">
						<AppStoreButton />
						<GooglePlayButton />
					</div>
				</div>
			</div>
		</section>
	);
}

export default Hero;
