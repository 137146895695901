import React, { useEffect, useState, useMemo } from 'react';
import * as styles from './TheHeader.scss';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { HashLink as Link } from 'react-router-hash-link';

function LanguageOption({ children, className, isDisabled, isFocused, isSelected, onFocus, onSelect, option }) {
	const handleMouseDown = event => {
		event.preventDefault();
		event.stopPropagation();
		onSelect(option, event);
	};

	const handleMouseEnter = event => {
		onFocus(option, event);
	};

	const handleMouseMove = event => {
		if (isFocused) return;
		onFocus(option, event);
	};

	return (
		<div className={className} onMouseDown={handleMouseDown} onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove}>
			<img src={`assets/${option}.png`} />
			{children}
		</div>
	);
}

function LanguageValue({ value, children }) {
	return <img src={`assets/${value}.png`} />;
}

/**
 * Заголовок
 * @param {Boolean} alwaysWhite Всегда белый стиль заголовка
 */
function TheHeader({alwaysWhite}) {
	const [headerClass, setHeaderClass] = useState(alwaysWhite ? 'scroll' : 0);
	const { t, i18n } = useTranslation();
	const languages = [
		{
			value: 'ru',
			label: <img src={`assets/ru.png`} height="30px" width="30px" />,
		},
		{
			value: 'en',
			label: <img src={`assets/en.png`} height="30px" width="30px" />,
		},
	];

	// Текущий выбранный язык как объект из выпадающего списка
	const currentLanguage = useMemo(() => {
		return languages.find(e => e.value == i18n.language) || languages[0];
	}, [i18n.language]);

	/**
	 * Чтобы при скролле красиво схлопывался заголовок
	 */
	useEffect(() => {
		if (!alwaysWhite) {
			window.addEventListener('scroll', handleScroll);
		} 

		return () => {
			if (!alwaysWhite) {
				window.removeEventListener('scroll', handleScroll);
			}
		};
	}, []);

	const handleScroll = () => {
		var scrollTop =
			window.scrollY ||
			window.pageYOffset ||
			document.body.scrollTop + ((document.documentElement && document.documentElement.scrollTop) || 0);
		setHeaderClass(72 < scrollTop ? 'scroll' : '');
	};

	const links = [
		{ title: t('theHeader.advantages'), ref: '/#advantages' },
		{ title: t('theHeader.faq'), ref: '/#faq' },
		{ title: t('theHeader.contacts'), ref: '/#contacts' },
	];

	/**
	 * Задать язык интерфейса
	 * @param {Object} lang Язык интерфейса
	 */
	const setActiveLanguage = function(lang) {
		window.localStorage.i18nextLng = lang.value;
		i18n.changeLanguage(lang.value);
	};

	return (
		<header className="header">
			<nav className={'navbar navbar-light fixed-top navbar-expand-md ' + headerClass}>
				<div className="container">
					<a className="navbar-brand" href="/#">
						<img src="/brand.png" className="navbar-brand-icon"></img>
						<span className="navbar-brand-text">WorkNote</span>
					</a>

					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbar"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							{links.map((link,index) => (
								<li key={index} className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
									<Link className="nav-link" to={link.ref}>
										{link.title}
									</Link>
								</li>
							))}
						</ul>

						<Select
							value={currentLanguage}
							onChange={setActiveLanguage}
							tabSelectsValue={false}
							placeholder={false}
							optionComponent={LanguageOption}
							options={languages}
							valueComponent={LanguageValue}
							components={{
								IndicatorSeparator: () => null,
							}}
							isSearchable={false}
							className="language-selector"
							classNamePrefix="react-select"
							styles={{
								control: (base, state) => ({
									...base,
									background: 'transparent',
									borderColor: 'transparent',
									boxShadow: null,
									cursor: 'pointer',
									width: 80,
									'&:hover': {
										background: 'transparent',
										borderColor: 'transparent',
										boxShadow: null,
									},
								}),
								dropdownIndicator: (base, state) => ({
									...base,
									color: '#FFF',
									'&:hover': {
										color: '#f0f0f0',
									},
									transition: 'all .2s ease',
									transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
								}),
								menu: base => ({
									...base,
									borderRadius: 0,
									marginTop: 0,
									transition: 'all .2s ease',
								}),
								menuList: base => ({
									...base,
									padding: 0,
									transition: 'all .2s ease',
								}),
							}}
						/>
					</div>
				</div>
			</nav>
		</header>
	);
}

export default TheHeader;
