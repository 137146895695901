import React from 'react';
import TheHeader from '../components/TheHeader';
import Hero from '../components/Hero';
import Advantages from '../components/Advantages';
import Cases from '../components/Cases';
import Feedback from '../components/Feedback';
import Faq from '../components/Faq';
import GettingStarted from '../components/GettingStarted';
import Footer from '../components/Footer';

/** Главная вьюшка */
function MainView() {
	return (
        <>
            <TheHeader />
            <Hero />
            <Advantages />
            <Cases />
            {/* <Feedback /> */}
            <GettingStarted />
            <Faq />
            <Footer />
		</>
	);
}

export default MainView;
