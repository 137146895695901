import React from 'react';

/**
 * Скрин на Iphone 8
 */
function ScreenshotIphone8(props) {
	return (
		<div class={`marvel-device iphone8 silver ${props.className}`}>
			<div class="top-bar"></div>
			<div class="sleep"></div>
			<div class="volume"></div>
			<div class="camera"></div>
			<div class="sensor"></div>
			<div class="speaker"></div>
			<div class="screen">{props.children}</div>
			<div class="home"></div>
			<div class="bottom-bar"></div>
		</div>
	);
}

export default ScreenshotIphone8;
