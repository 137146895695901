import React from 'react';
import * as styles from './Faq.scss';
import FaqItem from './FaqItem';
import { useTranslation } from 'react-i18next';

/**
 * FAQ
 */
function Faq() {
	const { t, i18n } = useTranslation();

	const items = [
		{
			question: t('faq.cost.question'),
			answer: t('faq.cost.answer'),
		},
		{
			question: t('faq.time.question'),
			answer: t('faq.time.answer'),
		},
		{
			question: t('faq.protection.question'),
			answer: t('faq.protection.answer'),
		},
	];

	const contacts = [
		{
			name: t('faq.contactUs'),
			value: 'worknote@a2soft.ru',
			href: 'mailto',
		},
		// {
		// 	name: 'Телефон',
		// 	value: '8-800-555-35-35',
		// 	href: 'tel',
		// },
	];

	return (
		<section className="faq" id="faq">
			<div className="container">
				<div className="faq__title wow fadeInDown">
					<h1 className="faq__title-header">{t('faq.title')}</h1>
					{/* <h2 className="faq__title-description">
						Aliquam a augue suscipit, luctus neque purus ipsum neque dolor primis libero tempus, blandit posuere
						ligula varius magna congue cursus porta Aliquam a augue suscipit, luctus neque purus ipsum neque dolor
						primis libero tempus, blandit posuere ligula varius magna congue cursus porta
					</h2> */}
				</div>
				<div className="faq__items">
					{items.map((item, index) => {
						return <FaqItem key={item.question} question={item.question} answer={item.answer} index={index} />;
					})}
				</div>
				<section className="faq__contacts" id="contacts">
					<h2 className="faq__contacts-title">{t('faq.stillHaveQuestions')}</h2>
					<div className="faq__contacts-items" id="contacts">
						{contacts.map((item, index) => {
							return (
								<div className="faq__contacts-items-item" key={index}>
									<p className="faq__contacts-items-item-name">{item.name}:</p>
									<p className="faq__contacts-items-item-value">
										<a href={`${item.href}:${item.value}`}>{item.value}</a>
									</p>
								</div>
							);
						})}
					</div>
				</section>
			</div>
		</section>
	);
}

export default Faq;
