import React from 'react';
import * as styles from './CaseItem.scss';
import ScreenshotIphoneXs from './common/ScreenshotIphoneXs';

/**
 * Один кейс
 * @param {*} image Большая картинка слева
 * @param {*} headerIcon Иконка около заголовка
 * @param {*} color Цвет иконки около заголовка
 * @param {*} header Заголовок
 * @param {*} description Описание
 * @param {*} isReverse Реверс или нет
 */
function CaseItem({ image, headerIcon, color, header, description, isReverse }) {
	return (
		<div className={`item ${isReverse ? 'item-reverse' : ''}`}>
			<div className="container item__body">
				<div className={`item__body-image wow ${isReverse ? 'fadeInRight' : 'fadeInLeft'}`}>
					<ScreenshotIphoneXs className="marvel-device-scale">
						<img src={image} />
					</ScreenshotIphoneXs>
				</div>
				<div className="item__body-information wow fadeInUp">
					<div className="item__body-information-header">
						<i
							class={'item__body-information-header-icon fa ' + headerIcon}
							aria-hidden="true"
							style={{ color: color }}
						></i>
						<h1 className="item__body-information-header-text">{header}</h1>
					</div>
					<div className="item__body-information-description">{description}</div>
				</div>
			</div>
		</div>
	);
}

export default CaseItem;
