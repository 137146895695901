import React, { useEffect } from 'react';
import './PrivacyPolicy.scss';
import { useTranslation } from 'react-i18next';

/**
 * Политика конфиденциальности
 */
function PrivacyPolicy({ lang }) {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [i18n, lang])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="privacy">
            <div className="privacy__title">{t('privacy.title')}</div>
            <div className="privacy__body">
                <div className="container">
                    <li className="privacy__body-item">{t('privacy.start')}</li>
                    <li className="privacy__body-item">{t('privacy.request')}</li>
                    <li className="privacy__body-item">{t('privacy.forWhat')}</li>
                    <li className="privacy__body-item">{t('privacy.necessity')}</li>
                    <li className="privacy__body-item">{t('privacy.use')}</li>
                    <li className="privacy__body-item">{t('privacy.questions')}</li>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
