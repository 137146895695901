import React, { useState } from 'react';
import * as styles from './FaqItem.scss';
import chevronDown from '../assets/chevron-down-solid.svg';
import { Collapse } from 'react-collapse';

/**
 * Один кейс
 * @param {*} question Большая картинка слева
 * @param {*} answer Иконка около заголовка
 */
function FaqItem({ question, answer, index }) {
	// Раскрыт ли текущий вопрос
	const [selected, setSelected] = useState(false);

	return (
		<div className="faq-item wow fadeInDown" data-wow-delay={`${index * 0.1}s`}>
			<div className="faq-item__question" onClick={() => setSelected(!selected)}>
				<div className="faq-item__question-title">{question}</div>
				<div className="faq-item__question-icon">
					<img src={chevronDown} className={selected ? 'selected' : ''} />
				</div>
			</div>
			<Collapse isOpened={selected}>
				<div className="faq-item__answer">{answer}</div>
			</Collapse>
		</div>
	);
}

export default FaqItem;
